<template>
  <div>
    <p-header />
    <slot />
    <p-footer v-if="showFooter" />
  </div>
</template>

<script lang="ts">
import PHeader from './PHeader/index.vue'
import PFooter from './PFooter/index.vue'

export default {
  components: {
    PHeader,
    PFooter,
  },

  setup() {
    const route = useRoute()

    const showFooter = computed(() => {
      return route.path === '/' || route.name === 'index'
    })

    return {
      showFooter,
    }
  },
}
</script>
